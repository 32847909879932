@media only screen and (min-width: 992px) {

  .carousel-inner {
    height: 40vw;
    max-height: 75vh;
    .carousel-item {
      height: 40vw;
      max-height: 75vh;
    }
  }
  .navbar-brand {
    img {
      width: 300px;
    }
  }
  .navbar-nav {
    margin-left: auto;
  }
  .nav-item {
    padding-left: 20px;
    padding-right: 20px;
    & a {
      text-transform: uppercase;
      font-size: 18px;
    }
  }
}
@media only screen and (min-width : 1200px) {
}
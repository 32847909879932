//font imports
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Open+Sans');

// fonts
$font: 'Libre Baskerville', serif;
$alt-font: 'Open Sans', sans-serif;

// colors
$blue: #0f3452;
$red: #8d292a;
$white: #fff;
$black: #000;
$trans-white: rgba(255,255,255,0.75);
$dark-gray: #565656;
$trans-gray: rgba(86,86,86,0.75);


// shadows and gradients
$bottom-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.37);
$top-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.37);


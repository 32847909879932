$theme-colors: (
        "primary": $blue,
        "danger": $red
);

body {
  h1, h2, h3, h4, h5, blockquote {
    font-family: $font!important;
  }
  p, ul>li, ol>li, a {
    font-family: $alt-font!important;
  }
}

.carousel-item {
  background-color: $white;
}
.carousel-item > img {
  top: -50%;
  left: 0;
  min-width: 100%;
  height: auto;
  opacity: 0.6;
}
.carousel-caption  {
  &>h1, &>p {
    color: $black;
    font-weight: 800;
  }
}

.graybackground {
  background-color: #b5b5b51f;
}

nav.bg-inverse {
  background-color: $blue !important;
}
.navbar-brand {
  img {
    max-width: 350px;
    width: 100%;
  }
}
.nav-link {
  color: white;
}

.main {
  margin-top: -47px;
}

i {
  color: lighten($red, 15%);
}

.dropdown-menu {
  background-color: #B8CFE2;
  border-radius: 0px;
  box-shadow: none;
}

#int-body {
  padding-top: 50px;
}

.cities {
  & > div {
    min-height: 75px;
    background-color: lighten($blue, 20%);
  }
}

@media only screen and (min-width: 425px) {
  #int-body {
    padding-top: 70px;
  }
}
